<template>
  <ratings-system-manager
    :username="username"
  ></ratings-system-manager>
</template>

<script>
import RatingsSystemManager from '@/components/Ratings/Manager'

export default {
  props: ['username'],
  components: {
    RatingsSystemManager
  }
}
</script>
