<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <v-col cols="12" class="pt-0">
        <v-tabs
          background-color="color3"
          slider-color="color3Text"
        >
          <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
            exact
            :to="tab.to"
            class="color2Text--text"
            active-class="color3"
          >{{tab.text}}</v-tab>
        </v-tabs>
        <loading-bar :value="loading.length > 0"></loading-bar>
          <v-container fluid>
            <v-row dense>
              <v-expand-transition mode="out-in">
                <v-col cols="12" v-if="systems.length > 1" class="pa-3">
                  <v-select
                    :items="systems"
                    v-model="ratingSystem"
                    label="Rating System"
                    item-text="name"
                    return-object
                    color="color3"
                    item-color="color3"
                    outlined dense hide-details
                  ></v-select>
                </v-col>
              </v-expand-transition>
              <v-col cols="12">
                <v-expand-transition mode="out-in">
                  <router-view
                    v-if="ratingSystem"
                    :key="ratingSystem.id"
                    :username="username"
                    :ratingSystem="ratingSystem"
                    :options="options"
                    @changed="onUpdate"
                  ></router-view>
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-container>
      </v-col>
    </v-row>
    <v-row dense v-if="page.ratingsOnly && showAdd" class="justify-center">
      <v-btn
        color="color3 color3Text--text"
        @click.stop="addSystem"
        :loading="loading.length > 0"
      >Add Rating System</v-btn>
    </v-row>
    <v-row dense class="justify-end">
      <v-btn
        color="color3"
        class="mx-3 mt-n3"
        text icon x-small
        :loading="loading.length > 0"
        @click.stop="loadRatingSystem"
      >
        <v-icon>fas fa-redo</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['username'],
  data () {
    return {
      loading: [],
      systems: [],
      ratingSystem: null,
      options: null
    }
  },
  computed: {
    ...mapGetters(['getPageInfo']),
    page () {
      return this.getPageInfo(this.username)
    },
    tabs () {
      return [
        { text: 'Settings', to: { name: 'ratings-manager-settings', params: { username: this.username } }, show: true },
        { text: 'Levels', to: { name: 'ratings-manager-levels', params: { username: this.username } }, show: true },
        { text: 'Results', to: { name: 'ratings-manager-results', params: { username: this.username } }, show: true },
        { text: 'Organizations', to: { name: 'ratings-manager-organizations', params: { username: this.username } }, show: true },
        { text: 'Players', to: { name: 'ratings-manager-players', params: { username: this.username } }, show: true }
      ].filter(f => f.show)
    },
    showAdd () {
      return this.$route.name === 'ratings-manager-settings'
    }
  },
  methods: {
    setData (dto) {
      this.systems = dto
      if (!this.ratingSystem) {
        this.ratingSystem = this.systems[0]
      } else {
        this.ratingSystem = this.systems.find(f => f.id === this.ratingSystem.id)
      }
    },
    addSystem () {
      this.loading.push(3)
      this.$VBL.ratings.add(this.page.id)
        .then(r => {
          this.setData(r.data)
        })
        .catch(e => console.log(e))
        .finally(f => { this.loading = this.loading.filter(f => f !== 3) })
    },
    loadRatingSystem () {
      this.loading.push(1)
      this.$VBL.ratings.get(this.username)
        .then(r => {
          this.setData(r.data)
        })
        .catch(e => console.log(e))
        .finally(f => { this.loading = this.loading.filter(f => f !== 1) })
    },
    onUpdate (dto) {
      this.setData(dto)
    },
    loadOptions () {
      this.loading.push(2)
      this.$http.get(`${window.location.origin}/static/json/TournamentOptions.json`)
        .then(r => { this.options = r.data })
        .finally(f => { this.loading = this.loading.filter(f => f !== 2) })
    }
  },
  mounted () {
    this.loadRatingSystem()
    this.loadOptions()
  },
  components: {
  }
}
</script>
